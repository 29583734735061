const HOST = "https://sita-stock-backend.herokuapp.com";
//const HOST = "http://localhost:8080";
let URLDICT = {
  "SYSTEM_LOGIN": "/system/login",
  "SYSTEM_LOGOUT": "/system/logout",
  "CHANGE_PASSWORD":"/system/changepassword",
  "KAFKA_TEST":"/system/kafkatest",
  // "SYSTEM_RELOADMENU": "/common/menuReload",

  // "AUTH_RIGHT_SEARCH": "/auth_right/search",
  // "AUTH_RIGHT_CREATE": "/auth_right/create",
  // "AUTH_RIGHT_DELETE": "/auth_right/delete",
  // "AUTH_RIGHT_EDIT": "/auth_right/edit",
  // "AUTH_RIGHT_DISABLE": "/auth_right/disable",
  // "AUTH_RIGHT_ENABLE": "/auth_right/enable",

  // "AUTH_ROLE_SEARCH": "/auth_role/search",
  // "AUTH_ROLE_CREATE": "/auth_role/create",
  // "AUTH_ROLE_DELETE": "/auth_role/delete",
  // "AUTH_ROLE_EDIT": "/auth_role/edit",
  // "AUTH_ROLE_DISABLE": "/auth_role/disable",
  // "AUTH_ROLE_ENABLE": "/auth_role/enable",

  // "AUTH_ACCOUNT_SEARCH": "/auth_account/search",
  // "AUTH_ACCOUNT_CREATE": "/auth_account/create",
  // "AUTH_ACCOUNT_DELETE": "/auth_account/delete",
  // "AUTH_ACCOUNT_EDIT": "/auth_account/edit",
  // "AUTH_ACCOUNT_DISABLE": "/auth_account/disable",
  // "AUTH_ACCOUNT_ENABLE": "/auth_account/enable",

  // "PROCESS_SEARCH": "/process/search",
  //"PROCESS_MYREQUEST_SEARCH":"/process/myReqSearch",
  //"PROCESS_PENDING_SEARCH":"/process/myPendingSearch",
  // "PROCESS_EDIT": "/process/edit",

  // "MASTERFILE_REPO_SEARCH": "/masterfile_repo/search",
  // "MASTERFILE_REPO_CREATE": "/masterfile_repo/create",
  // "MASTERFILE_REPO_DELETE": "/masterfile_repo/delete",
  // "MASTERFILE_REPO_EDIT": "/masterfile_repo/edit",
  // "MASTERFILE_REPO_DISABLE": "/masterfile_repo/disable",
  // "MASTERFILE_REPO_ENABLE": "/masterfile_repo/enable",
  // "MASTERFILE_REPO_SORTSEARCH": "/masterfile_repo/sortSearch",

  // "WORKFLOW_FLOW_SEARCH": "/workflow_flow/search",
  // "WORKFLOW_FLOW_CREATE": "/workflow_flow/create",
  // "WORKFLOW_FLOW_DELETE": "/workflow_flow/delete",
  // "WORKFLOW_FLOW_EDIT": "/workflow_flow/edit",
  // "WORKFLOW_FLOW_DISABLE": "/workflow_flow/disable",
  // "WORKFLOW_FLOW_ENABLE": "/workflow_flow/enable",

  // "MASTERFILE_ITEM_SEARCH": "/masterfile_item/search",
  // "MASTERFILE_ITEM_CREATE": "/masterfile_item/create",
  // "MASTERFILE_ITEM_DELETE": "/masterfile_item/delete",
  // "MASTERFILE_ITEM_EDIT": "/masterfile_item/edit",
  // "MASTERFILE_ITEM_DISABLE": "/masterfile_item/disable",
  // "MASTERFILE_ITEM_ENABLE": "/masterfile_item/enable",

  // "MASTERFILE_CLASS_SEARCH": "/masterfile_class/search",
  // "MASTERFILE_CLASS_CREATE": "/masterfile_class/create",
  // "MASTERFILE_CLASS_DELETE": "/masterfile_class/delete",
  // "MASTERFILE_CLASS_EDIT": "/masterfile_class/edit",
  // "MASTERFILE_CLASS_DISABLE": "/masterfile_class/disable",
  // "MASTERFILE_CLASS_ENABLE": "/masterfile_class/enable",
  // "MASTERFILE_CLASS_SORTSEARCH": "/masterfile_class/sortSearch",

  // "MASTERFILE_SKU_SEARCH": "/masterfile_sku/search",
  // "MASTERFILE_SKU_CREATE": "/masterfile_sku/create",
  // "MASTERFILE_SKU_DELETE": "/masterfile_sku/delete",
  // "MASTERFILE_SKU_EDIT": "/masterfile_sku/edit",
  // "MASTERFILE_SKU_DISABLE": "/masterfile_sku/disable",
  // "MASTERFILE_SKU_ENABLE": "/masterfile_sku/enable",
  // "MASTERFILE_SKU_SORTSEARCH": "/masterfile_sku/sortSearch",

  // "MASTERFILE_TYPE_SEARCH": "/masterfile_type/search",
  // "MASTERFILE_TYPE_CREATE": "/masterfile_type/create",
  // "MASTERFILE_TYPE_DELETE": "/masterfile_type/delete",
  // "MASTERFILE_TYPE_EDIT": "/masterfile_type/edit",
  // "MASTERFILE_TYPE_DISABLE": "/masterfile_type/disable",
  // "MASTERFILE_TYPE_ENABLE": "/masterfile_type/enable",
  // "MASTERFILE_TYPE_SORTSEARCH": "/masterfile_type/sortSearch",

  // "MASTERFILE_ATTR_SEARCH": "/masterfile_attr/search",
  // "MASTERFILE_ATTR_CREATE": "/masterfile_attr/create",
  // "MASTERFILE_ATTR_DELETE": "/masterfile_attr/delete",
  // "MASTERFILE_ATTR_EDIT": "/masterfile_attr/edit",
  // "MASTERFILE_ATTR_DISABLE": "/masterfile_attr/disable",
  // "MASTERFILE_ATTR_ENABLE": "/masterfile_attr/enable",
  // "MASTERFILE_ATTR_SORTSEARCH": "/masterfile_attr/sortSearch",

  // "MASTERFILE_ATTR_VALUE_SEARCH": "/masterfile_attr_value/search",
  // "MASTERFILE_ATTR_VALUE_CREATE": "/masterfile_attr_value/create",
  // "MASTERFILE_ATTR_VALUE_DELETE": "/masterfile_attr_value/delete",
  // "MASTERFILE_ATTR_VALUE_EDIT": "/masterfile_attr_value/edit",
  // "MASTERFILE_ATTR_VALUE_DISABLE": "/masterfile_attr_value/disable",
  // "MASTERFILE_ATTR_VALUE_ENABLE": "/masterfile_attr_value/enable",
  // "MASTERFILE_ATTR_VALUE_SORTSEARCH": "/masterfile_attr_value/sortSearch",

  // "MASTERFILE_SPEC_SEARCH": "/masterfile_spec/search",
  // "MASTERFILE_SPEC_CREATE": "/masterfile_spec/create",
  // "MASTERFILE_SPEC_DELETE": "/masterfile_spec/delete",
  // "MASTERFILE_SPEC_EDIT": "/masterfile_spec/edit",
  // "MASTERFILE_SPEC_DISABLE": "/masterfile_spec/disable",
  // "MASTERFILE_SPEC_ENABLE": "/masterfile_spec/enable",
  // "MASTERFILE_SPEC_SORTSEARCH": "/masterfile_spec/sortSearch",

  // "COMMON_RIGHT_MODULE": "/common/rightModule",
  "COMMON_SKU_MODULE": "/common/skuModule",
  "COMMON_SKUBYUAM_MODULE": "/common/skuModuleByUam",
  // "COMMON_SPEC_MODULE": "/common/specModule",
  // "COMMON_TYPE_MODULE": "/common/typeModule",
  // "COMMON_ATTR_MODULE": "/common/attrModule",
  // "COMMON_ATTR_VALUE_MODULE": "/common/attrValueModule",
  // "COMMON_CLASS_MODULE": "/common/classValueModule",
  // "COMMON_REPO_MODULE": "/common/repoModule",
  // "COMMON_ATTR_ATTR_VALUE_MODULE": "/common/attrAttrValueModule",
  // "COMMON_ADJUST_REASON_MODULE": "/common/adjustReasonModule",
  // "COMMON_ROLE_MODULE": "/common/roleModule",
  "COMMON_STOCK_TYPE_MODULE": "/common/stockTypeModule",
  "COMMON_TRX_TYPE_MODULE": "/common/trxTypeModule",
  // "COMMON_FLOW_NATURE_MODULE": "/common/flowModule",
  "COMMON_GET_USERSESSION": "/common/searchUserSession",
  // "COMMON_ORDER_STATUS_MODULE": "/common/orderStatusModule",
  "COMMON_UAM_ROLE_MODULE": "/common/uamRoleModule",
  "COMMON_UAM_USER_MODULE": "/common/uamUserModule",
  // "COMMON_UAM_ROLEFUNCTIONMAP_MODULE": "/common/uamRoleFunMapModule",
  "COMMON_UAM_ROLEFUNCTION_MODULE": "/common/uamFunListModule",
  // "COMMON_SEARCH_SKUBYCHANNELID": "/common/searchSkuByRepoId",
  "COMMON_SEARCH_ITEMBYCHANNELIDANDCONDITIONID": "/common/searchItemByChannelIdAndCondition",
  "COMMON_ITEMMASTERBYID": "/common/itemMasterById",
  // "COMMON_SEARCH_REPOMODULEBYUAM": "/common/repoModuleByUam",
  "COMMON_REQUESTSTATUSMODULE": "/common/requestStatusModule",
  // "COMMON_REQUESTLABELMODULE": "/common/requestLabelModule",
  "COMMON_UAM_FUNCTION_SEARCH": "/uam_function_list/searchAccess",
  "COMMON_UAM_FUNCTION_LIST": "/common/uamFunListModule",

  "COMMON_ASSIGNMENTLOGIC": "/common/assignmentLogic",
  "COMMON_RESSTOCKRESERVATIONTYPE": "/common/resStockReservationType",
  "COMMON_RESERVATION_REASON": "/common/stockReservationReason",
  // "COMMON_RESREQUESTSTATUS": "/common/resRequestStatus",
  "COMMON_SYSTEMMASTER": "/common/sysMaster",
  "COMMON_STOCKALLOCATIONTYPE": "/common/stockAllocationType",
  "COMMON_STOCKCONDITION": "/common/resStockCondition",
  "COMMON_SOURCESYSTEM": "/common/sourceSystem",
  "COMMON_REQUESTURL": "/common/requesturl",
  "COMMON_API_TYPE":"/common/apitype",
  "SYS_DEF_LOOKUP_DESC":"/sys_def_lookup/getSysDefLookup",
  "COMMON_STOCK_TAKE_BULK_ADD_FILTER": "/common/stockTakeBulkAddFilter",
  "COMMON_BRAND_NAME": "/common/brandName",
  "COMMON_STOCK_TAKE_COUNT_BY": "/common/stockTakeCountBy",
  "COMMON_REPO_MODULE_BY_MERGE": "/common/repoModuleByMerge",
  "COMMON_GET_DEFAULT_CHANNEL": "/common/getDefaultChannel",
  // "MASTERFILE_ADJUST_REASON_SEARCH": "/masterfile_adjust_reason/search",
  // "MASTERFILE_ADJUST_REASON_CREATE": "/masterfile_adjust_reason/create",
  // "MASTERFILE_ADJUST_REASON_DELETE": "/masterfile_adjust_reason/delete",
  // "MASTERFILE_ADJUST_REASON_EDIT": "/masterfile_adjust_reason/edit",
  // "MASTERFILE_ADJUST_REASON_DISABLE": "/masterfile_adjust_reason/disable",
  // "MASTERFILE_ADJUST_REASON_ENABLE": "/masterfile_adjust_reason/enable",
  // "MASTERFILE_ADJUST_REASON_SORTSEARCH": "/masterfile_adjust_reason/sortSearch",

  // "MASTERFILE_STOCK_TYPE_SEARCH": "/masterfile_stock_type/search",
  // "MASTERFILE_STOCK_TYPE_CREATE": "/masterfile_stock_type/create",
  // "MASTERFILE_STOCK_TYPE_DELETE": "/masterfile_stock_type/delete",
  // "MASTERFILE_STOCK_TYPE_EDIT": "/masterfile_stock_type/edit",
  // "MASTERFILE_STOCK_TYPE_DISABLE": "/masterfile_stock_type/disable",
  // "MASTERFILE_STOCK_TYPE_ENABLE": "/masterfile_stock_type/enable",
  // "MASTERFILE_STOCK_TYPE_SORTSEARCH": "/masterfile_stock_type/sortSearch",

  // "MASTERFILE_PRODUCTLINE_CREATE": "/masterfile_productline/create",
  // "MASTERFILE_PRODUCTLINE_SEARCH": "/masterfile_productline/search",
  // "MASTERFILE_PRODUCTLINE_DELETE": "/masterfile_productline/delete",
  // "MASTERFILE_PRODUCTLINE_EDIT": "/masterfile_productline/edit",
  // "MASTERFILE_PRODUCTLINE_DISABLE": "/masterfile_productline/disable",
  // "MASTERFILE_PRODUCTLINE_ENABLE": "/masterfile_productline/enable",

  // "MASTERFILE_CCC_CREATE": "/masterfile_ccc/create",
  // "MASTERFILE_CCC_SEARCH": "/masterfile_ccc/search",
  // "MASTERFILE_CCC_DELETE": "/masterfile_ccc/delete",
  // "MASTERFILE_CCC_EDIT": "/masterfile_ccc/edit",
  // "MASTERFILE_CCC_DISABLE": "/masterfile_ccc/disable",
  // "MASTERFILE_CCC_ENABLE": "/masterfile_ccc/enable",

  // "MASTERFILE_WORKORDER_CREATE": "/masterfile_workorder/create",
  // "MASTERFILE_WORKORDER_SEARCH": "/masterfile_workorder/search",
  // "MASTERFILE_WORKORDER_DELETE": "/masterfile_workorder/delete",
  // "MASTERFILE_WORKORDER_EDIT": "/masterfile_workorder/edit",
  // "MASTERFILE_WORKORDER_DISABLE": "/masterfile_workorder/disable",
  // "MASTERFILE_WORKORDER_ENABLE": "/masterfile_workorder/enable",

  // "MASTERFILE_TRXTYPE_CREATE": "/masterfile_trx_type/create",
  // "MASTERFILE_TRXTYPE_SEARCH": "/masterfile_trx_type/search",
  // "MASTERFILE_TRXTYPE_DELETE": "/masterfile_trx_type/delete",
  // "MASTERFILE_TRXTYPE_EDIT": "/masterfile_trx_type/edit",
  // "MASTERFILE_TRXTYPE_DISABLE": "/masterfile_trx_type/disable",
  // "MASTERFILE_TRXTYPE_ENABLE": "/masterfile_trx_type/enable",
  // "MASTERFILE_TRXTYPE_SORTSEARCH": "/masterfile_trx_type/sortSearch",
  // stock balance
  // "STOCK_CURD_CREATE": "/stock_curd/create",
  "STOCK_CURD_SEARCH": "/stock_curd/search",
  // "STOCK_CURD_DELETE": "/stock_curd/delete",
  // "STOCK_CURD_EDIT": "/stock_curd/edit",
  // "STOCK_CURD_DISABLE": "/stock_curd/disable",
  // "STOCK_CURD_ENABLE": "/stock_curd/enable",
  "STOCK_CURD_SORTSEARCH": "/stock_curd/sortSearch",
  "STOCK_CURD_PRINT": "/stock_curd/print",

  // "STOCK_OUT_CREATE": "/stock_out/create",
  // "STOCK_OUT_SKU": "/stock_out/search",
  // "STOCK_IN_PO_SEARCH": "/stock_in/searchStockOutInfo",
  // "STOCK_IN_PO_CREATE": "/stock_in/createPOInfos",
  // "STOCK_IN_CREATE": "/stock_in/create",
  // "STOCK_BALANCE_SEARCH": "/stock_balance/search",
  // "STOCK_ASSIGN_SEARCH": "/stock_assign/search",
  // "MASTERFILE_TYPE_SPEC_SEARCH": "/masterfile_type/specSearch",
  // "STOCK_ADJUSTMENT_CONFIRM": "/stock_adjustment/confirm",
  // "MASTERFILE_SKU_TYPESEARCH": "/masterfile_sku/typeSearch",
  // "MASTERFILE_ATTR_ATTRSEARCH": "/masterfile_attr/attrSearch",

  // "STOCK_TRANSACTIONS_CREATE": "/stock_transactions/create",
  // "STOCK_TRANSACTIONS_SKU": "/stock_transactions/availableSku",
  // "STOCK_TRANSACTIONS_SEARCH": "/stock_transactions/search",

  // "STOCK_CATEGORY_SEARCH": "/stock_category/search",
  // "STOCK_CATEGORY_CREAT": "/stock_category/create",
  "STOCK_MOVEMENT_SEARCH": "/stock_movement/search",
  "STOCK_MOVEMENT_SORTSEARCH": "/stock_movement/sortSearch",
  "STOCK_MOVEMENT_HISRORY_SEARCH": "/ppos_stock_trx_import/search",
  "STOCK_MOVEMENT_HISRORY_EXPORT": "/ppos_stock_trx_import/export",
  // "STOCK_ADJUSTMENT_BALANCESEARCH": "/stock_adjustment/balanceSearch",
  "STOCK_COMMON_SEARCHBYREPO": "/common/searchByRepo",
  "STOCK_ADJUSTMENT_SEARCHBYREPO": "/stock_adjustment/searchByRepo",
  // "STOCK_ADJUSTMENT_UPDATE": "/stockAdjustment/updateStatus",


  "STOCK_TRANSFER_STATUSSEARCH": "/stock_transfer_order/statusSearch",
  "STOCK_CHANNEL_TRANSFERCREATE": "/stocks/transfer-order",
  "STOCK_TRANSFER_STOCKIN": "/stocks/transfer-order",
  "STOCK_ORDER_EDIT": "/stocks/transfer-order",
  // "STOCK_TRANSFER_STOCKIN_UPDATE": "/stocks/transfer-order-status",
  "STOCK_CHANNEL_TRANSFER_UPDATE": "/stocks/transfer-order-status",
  "STOCK_TRANSFER_ORDERINFO": "/stock_transfer_order/showTransferOrderInfo",
  "STOCK_TRANSFER_SORTORDERINFO": "/stock_transfer_order/showTransferOrderInfoSort",
  "STOCK_TRANSFER_LOADTOSTOCKIN": "/stock_transfer_order/showTransferOrderInfoToDoStockIn",
  "STOCK_TRANSFER_GETFROMNATURE": "/stock_transfer_order/getNature",
  "STOCK_TRANSFER_GETTONATURE": "/stock_transfer_order/getToNature",
  // "STOCK_TRANSFER_RETURNCREATE": "/stock_return/createReturnOrder",
  // "STOCK_TRANSFER_RETURNUPDATE": "/stock_return/updateStatus",
  "STOCK_TRANSFER_CONFIRM": "/stock_transfer_order/getConfirmTransferOrder",
  "STOCK_TRANSFER_PRINT": "/stock_transfer_order/printPdf",
  "STOCK_TRANSFER_SORTORDERINFOBYITEMSORT": "/stock_transfer_order/showTransferOrderInfoByItemSort",

  "STOCK_TRANSFER_GET_ORDER_DETAIL_BY_ID": "/stock_transfer_order/getOrderDetailToExcel",
  "STOCK_TRANSFER_GET_ORDER_ITEMS_BY_ID": "/stock_transfer_order/getOrderItemsToExcel",
  "STOCK_TRANSFER_GET_ORDER_SERIALS_BY_ID": "/stock_transfer_order/getOrderSerialsToExcel",

  "STOCK_ADJUSTMENT_SEARCH": "/stockAdjustment/search",
  // "STOCK_ADJUSTMENT_CREATE": "/stockAdjustment/save",
  "STOCK_ADJUSTMENT_GETRESONS": "/stockAdjustment/getReasons",
  // "STOCK_ADJUSTMENT_SEARCHBYID": "/stockAdjustment/search",
  // "STOCK_ADJUSTMENT_GETFROMNATURES": "/stockAdjustment/getFromNatures",
  // "STOCK_ADJUSTMENT_GET_TAKE_REF": "/stock/stock-take/getStockTakeListForAdjustment",
  "STOCK_ADJUSTMENT_NET_AMOUNT": "/stocks/getAdjustmentAmount",
  "STOCK_ORDER_CHECK_RESERVE": "/stocks/checkBalanceReserve",
  "STOCK_CHECK_SERIAL_EXIST": "/stocks/checkSerialIsExist",
  "STOCK_RESERVABLE": "/stocks/checkReservable",

  // "AUTH_ACCOUNT_SEARCHBYID": "/auth_account/searchById",
  // "STOCK_CATEGORY_SKUSTOCKTYPESEARCH": "/stock_category/skuStockTypeSearch",

  "STOCK_TRANSFER_ORDERTYPE": "/stock_transfer_order/orderTypeSearch",
  "STOCK_TRANSFER_ALLORDERTYPE": "/stock_transfer_order/allOrderTypeSearch",
  "STOCK_HISTORY_TRANSACTION_TYPE": "/stock_movement/transactionType",

  "STOCK_ITEM_MASTER_SEARCH": "/masterfile_sku/masterSearch",
  "STOCK_BACKEND_TRANSACTION_SAVE": "/api/v2/stocks/transaction",

  // "STOCK_THRESHOLD_CREATE": "/stock_threshold/create",
  // "STOCK_THRESHOLD_SEARCH": "/stock_threshold/search",
  // "STOCK_THRESHOLD_SORTSEARCH": "/stock_threshold/sortSearch",
  // "STOCK_THRESHOLD_EDIT": "/stock_threshold/edit",
  // "STOCK_THRESHOLD_DISABLE": "/stock_threshold/disable",
  // "STOCK_THRESHOLD_ENABLE": "/stock_threshold/enable",

  "STOCK_TAKE_SEARCH": "/stock/stock-take/search",
  "STOCK_TAKE_SORTSEARCH": "/stock/stock-take/sortSearch",
  "STOCK_TAKE_SEARCH_DETAIL": "/stock/stock-take/searchDetail",
  "STOCK_TAKE_CREATE": "/stock/stock-take/create",
  "STOCK_TAKE_SAVE": "/stock/stock-take/update",
  "STOCK_TAKE_LINE_BATCH_UPDATE": "/stock/stock-take/batchUpdateTakeLine",
  "STOCK_TAKE_CONFORSUBMIT": "/stock/stock-take/confOrSubmit",
  // "STOCK_TAKE_DELETE": "/stock/stock-take/delete",
  // "STOCK_TAKE_EDIT": "/stock/stock-take/edit",
  // "STOCK_TAKE_PRINT": "/stock/stock-take/print",
  "STOCK_TAKE_DOWNLOAD": "/stock/stock-take/download",
  // "STOCK_TAKE_DOWNLOAD_TEMPLATE": "/stock/stock-take/downloadBefore",
  // "STOCK_TAKE_SEARCH_CURRENTQTY": "/stock/stock-take/searchSkuQty",
  // "STOCK_TAKE_SEARCH_SKUBYCHANNELID": "/stock/stock-take/searchSkuByRepoId",
  "STOCK_TAKE_VIEW_VARIANCE_REPORT": "/stock/stock-take/viewVarianceReport",
  "STOCK_TAKE_CHECK_VARIANCE": "/stock/stock-take/checkVariance",
  "STOCK_TAKE_READY": "/stock/stock-take/readyForStockTake",
  "STOCK_TAKE_LABEL": "/stock/stock-take/stockTakeLabel",
  "STOCK_TAKE_STATUS": "/stock/stock-take/stockTakeStatus",
  "STOCK_TAKE_SEARCH_SERIAL": "/stock/stock-take/searchStockTakeLineSerial",
  "STOCK_TAKE_SHOW_SERIAL": "/stock/stock-take/showStockTakeLineSerial",
  "STOCK_TAKE_APPEND_SERIAL": "/stock/stock-take/appendStockTakeLineSerial",
  "STOCK_TAKE_DELETE_SERIAL": "/stock/stock-take/deleteStockTakeLineSerial",
  "STOCK_TAKE_COMPARE_SERIAL": "/stock/stock-take/compareStockTakeLineSerial",
  "STOCK_TAKE_COMPARE_ALL_SERIAL": "/stock/stock-take/compareCountSerial",
  // "STOCK_TAKE_SUBMIT_SERIAL": "/stock/stock-take/confOrSubmit",
  "STOCK_TAKE_PRE_STOCK_TAKE": "/stock/stock-take/preStockTake",
  "STOCK_TAKE_MARK_AS_FIRST_COUNT": "/stock/stock-take/markAsFirstCount",
  "STOCK_TAKE_READY_SECOND_COUNT": "/stock/stock-take/readyFor2ndCount",
  "STOCK_TAKE_SAVE_2ND_SERIAL": "/stock/stock-take/save2ndSerial",
  "STOCK_TAKE_MARK_AS__SECOND_COUNT": "/stock/stock-take/markAs2ndCount",
  "STOCK_TAKE_BACK_DETAIL": "/stock/stock-take/backTo2ndCountDetail",
  "STOCK_TAKE_ADJUST_IN_PROGRESS": "/stock/stock-take/adjustInProgress",
  "STOCK_TAKE_UPDATE_STATUS": "/stock/stock-take/updateStockTakeStatus",
  "STOCK_TAKE_GET_SNAPSHOT_SERIAL_LIST": "/stock/stock-take/getSerialSnapshotList",
  "STOCK_TAKE_GET_CANCEL_WITH_REASON": "/stock/stock-take/updateStockTakeCancelReason",
  "STOCK_TAKE_GET_SNAPSHOT_LINE": "/stock/stock-take/getSnapshotLine",

  "LIS_STOCK_INTERFACE_UPDATEOCC":"/lis_stock_interface/updateOCC",
  "LIS_STOCK_INTERFACE_DELETEBYIDS": "/lis_stock_interface/deleteByIds",

  // "STOCK_RESERVATION_CREATE": "/stock_reservation/create",
  "STOCK_RESERVATION_SEARCH": "/stock_reservation/search",
  "STOCK_RESERVATION_CREATE": "/stock_reservation/create",
  "STOCK_RESERVATION_UPDATE": "/stock_reservation/update",
  "STOCK_RESERVATION_DELETE": "/stock_reservation/delete",
  "STOCK_RESERVATION_SEARCHSTOCKALLOCATION": "/stock_reservation/searchStockAllocation",
  "STOCK_RESERVATION_STOCKALLOCATION_UPDATE": "/stock_reservation/stockAllocationUpdate",
  "STOCK_RESERVATION_LISALLOCATIONUPDATE": "/stock_reservation/lisAllocationUpdate",
  "STOCK_RESERVATION_EXTENDAUTORELEASEDATE": "/stock_reservation/extendAutoReleaseDate",
  "STOCK_RESERVATION_CANCEL": "/stock_reservation/cancelReservation",
  "CHANNEL_REPLENISH_GET_ADDRESS3_LIST": "/channel_replenish/getAddress3List",
  "CHANNEL_REPLENISH_GET_COURIER_NAME": "/channel_replenish/getCourierName",
  "CHANNEL_REPLENISH_NOP_CALCULATE_ALL": "/channel_replenish/nopCalculateAll",
  "CHANNEL_REPLENISH_GET_NEXT_REPLENISH_DATE": "/channel_replenish/getNextReplenishmentDate",
  "CHANNEL_REPLENISH_GET_PRE_ALLOCATED_RESERVATION": "/channel_replenish/getPreAllocatedStockReservation",
  "CHANNEL_REPLENISH_GET_PRE_ALLOCATED_RESERVATION_BY_SOURCEREFHEADERNO": "/channel_replenish/getPreAllocatedStockReservationBySourceTxnRefHeaderNo",
  // "STOCK_RESERVATION_SORTSEARCH": "/stock_reservation/sortSearch",

  // "STOCK_RESERVATION_RULE_CREATE": "/stock_reservation_rule/create",
  // "STOCK_RESERVATION_RULE_SEARCH": "/stock_reservation_rule/search",
  // "STOCK_RESERVATION_RULE_EDIT": "/stock_reservation_rule/edit",
  // "STOCK_RESERVATION_RULE_DELETE": "/stock_reservation_rule/delete",

  // "STOCK_RETURN_CREATE": "/stock_return/create",
  // "STOCK_RETURN_SEARCH": "/stock_return/search",
  // "STOCK_RETURN_EDIT": "/stock_return/edit",
  "STOCK_RETURN_DELETE": "/stock_return/delete",

  // "STOCK_REPLENISHMENT_CREATE": "/stock_replenishment/create",
  // "STOCK_REPLENISHMENT_SEARCH": "/stock_replenishment/search",
  // "STOCK_REPLENISHMENT_SORTSEARCH": "/stock_replenishment/sortSearch",
  // "STOCK_REPLENISHMENT_EDIT": "/stock_replenishment/edit",
  // "STOCK_REPLENISHMENT_DELETE": "/stock_replenishment/delete",
  // "STOCK_REPLENISHMENT_SUBMIT": "/stock_replenishment/submit",

  // "STOCK_REQUEST_CREATE": "/stock_request/create",
  // "STOCK_REQUEST_DELETE": "/stock_request/delete",
  // "STOCK_REQUEST_EDIT": "/stock_request/edit",
  // "STOCK_REQUEST_SEARCH": "/stock_request/search",
  // "STOCK_REQUEST_SORTSEARCH": "/stock_request/sortSearch",
  // "STOCK_REQUEST_SUBMIT": "/stock_request/submit",
  // "STOCK_REQUEST_DOWNLOAD": "/stock_request/download",
  // "STOCK_REQUEST_GETBALANCEINFO": "/stock_request/getBalanceInfo",

  // "ASSORTMENT_ALLOCATION_CREATE": "/assortment_allocation/create",
  // "ASSORTMENT_ALLOCATION_DELETE": "/assortment_allocation/delete",
  // "ASSORTMENT_ALLOCATION_DOWNLOAD": "/assortment_allocation/download",
  // "ASSORTMENT_ALLOCATION_EDIT": "/assortment_allocation/edit",
  // "ASSORTMENT_ALLOCATION_SEARCH": "/assortment_allocation/search",
  // "ASSORTMENT_ALLOCATION_SORTSEARCH": "/assortment_allocation/sortSearch",
  // "ASSORTMENT_ALLOCATION_SUBMIT": "/assortment_allocation/submit",
  // "ASSORTMENT_ALLOCATION_VERIFY": "/assortment_allocation/verify",
  // "ASSORTMENT_ALLOCATION_UPLOAD": "/assortment_allocation/upload",
  // "ASSORTMENT_ALLOCATION_GENERATEREPORT": "/assortment_allocation/generateReport",
  // "ASSORTMENT_ALLOCATION_GENERATECONFIRM": "/assortment_allocation/generateConfirm",

  // "STOCK_LOT_SEARCH": "/stock_lot/search",
  // "STOCK_LOT_SORTSEARCH": "/stock_lot/sortSearch",
  // "STOCK_LOT_CREATE": "/stock_lot/create",
  // "STOCK_LOT_EDIT": "/stock_lot/edit",
  // "STOCK_LOT_DELETE": "/stock_lot/delete",

  "STOCK_REPORT_PRINT": "/stock_report/print",
  "QTY_ONHAND_SERIAL_SEARCH": "/qtyOnhandSerial/search",

  "STOCK_DEMAND_CYCLE_SEARCH": "/demand_cycle/search",
  "STOCK_DEMAND_CYCLE_CREATE": "/demand_cycle/create",
  "STOCK_DEMAND_CYCLE_EDIT": "/demand_cycle/edit",
  "STOCK_DEMAND_CYCLE_POOLTYPE": "/demand_cycle/getStockPoolType",
  "STOCK_DEMAND_CYCLE_NAME_EXIST": "/demand_cycle/isDemandCycleNameExist",
  "STOCK_DEMAND_CYCLE_TYPE": "/demand_cycle/getDemandCycleTypes",

  "STOCK_DEMAND_SEARCH": "/demand_line/search",
  "STOCK_DEMAND_ADDITEM": "/demand_line/searchLineInfo",
  "STOCK_DEMAND_CREATE": "/demand_line/createDemandLine",
  "STOCK_DEMAND_EDIT": "/demand_line/editDemandLine",
  "STOCK_DEMAND_DELETE": "/demand_line/deleteDemandLine",

  "STOCK_DEMAND_REPLENISHMENT_SEARCH": "/channel_replenish/search",
  "STOCK_DEMAND_REPLENISHMENT_CREATE": "/channel_replenish/create",
  "STOCK_DEMAND_REPLENISHMENT_EDIT": "/channel_replenish/edit",
  "STOCK_DEMAND_REPLENISHMENT_VERIFY": "/channel_replenish/verify",
  "STOCK_DEMAND_REPLENISHMENT_RESERVE": "/channel_replenish/reserveLis",
  "STOCK_DEMAND_REPLENISHMENT_RERESERVE": "/channel_replenish/rereserveLis",
  "STOCK_DEMAND_REPLENISHMENT_RERESERVEBYLINEID": "/channel_replenish/rereserveLisByLineid",
  "STOCK_DEMAND_REPLENISHMENT_GET_CHANNELID_IN_REPLENISH": "/channel_replenish_line/getAllChannelIdsByReplenishId",
  "STOCK_DEMAND_REPLENISHMENT_RESERVE_NOP": "/channel_replenish/nopReserveLis",
  "STOCK_DEMAND_REPLENISHMENT_SUBMIT": "/channel_replenish/submit",
  "STOCK_DEMAND_REPLENISHMENT_RESUBMIT": "/channel_replenish/resubmitLis",
  "STOCK_DEMAND_REPLENISHMENT_SUBMITTODM": "/channel_replenish/submitToDm",
  "STOCK_DEMAND_REPLENISHMENT_DELETE": "/channel_replenish/delete",
  "STOCK_DEMAND_REPLENISHMENT_GET_BU_CODE": "/channel_replenish/getBuCode",
  "STOCK_DEMAND_REPLENISHMENT_LINE_SEARCH": "/channel_replenish_line/search",
  "STOCK_DEMAND_REPLENISHMENT_LINE_SCHOOL_SEARCH": "/channel_replenish_line/schCodeSearch",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEM": "/channel_replenish_line/addItem",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMS": "/channel_replenish_line/addItems",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMBYBU": "/channel_replenish_line/addItemsByBU",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADDITEMBYBU_AS_CSV": "/channel_replenish_line/addItemsByBuAsCsv",
  "STOCK_DEMAND_REPLENISHMENT_LINE_EXPORTITEMSBYBU": "/channel_replenish_line/exportItemsByBu",
  "STOCK_DEMAND_REPLENISHMENT_LINE_CANCEL_RESERVE_LIS": "/channel_replenish_line/cancelReserveLis",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_SCHOOL_CODE": "/channel_replenish_line/addItemBySchCode",
  "STOCK_DEMAND_REPLENISHMENT_PRE_ALLOCATE": "/channel_replenish/getPreAllocate",
  "STOCK_DEMAND_REPLENISHMENT_SAVE_PRE_ALLOCATE": "/channel_replenish/savePreAllocate",
  "STOCK_DEMAND_REPLENISHMENT_DELETE_PRE_ALLOCATE": "/channel_replenish/deletePreAllocate",
  "STOCK_DEMAND_REPLENISHMENT_PRE_ALLOCATE_ITEM": "/channel_replenish/preAllocate",
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_RESERVATION": '/channel_replenish_line/addItemByReservation',
  "STOCK_DEMAND_REPLENISHMENT_LINE_ADD_ITEM_BY_RESERVATION_BYOD": '/channel_replenish_line/addItemByReservationAndType',
  "STOCK_DEMAND_REPLENISHMENT_LINE_SEARCH_DM_LIST": '/channel_replenish_line/searchNopOrderList',
  "STOCK_DEMAND_REPLENISHMENT_LINE_GET_ITEMINFO_BY_CHANNEL_REPLENISH": '/channel_replenish_line/getItemInfoByChannelReplenish',
  "STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNRESERVE_LINE_ID": '/channel_replenish_line/getUnReserveReplenishLineByReplenishId',
  "STOCK_DEMAND_REPLENISHMENT_LINE_GET_UNSUBMIT_LINE_ID": '/channel_replenish_line/getReservedNoSubmitReplenishLineByReplenishId',
  "STOCK_DEMAND_REPLENISHMENT_SUBMIT_BY_ID": '/channel_replenish/submitLisById',
  "STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_SUBMIT": '/channel_replenish/updateToSubmit',
  "STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_RESERVE": '/channel_replenish/updateToReserve',
  "STOCK_DEMAND_REPLENISHMENT_UPDATE_REPLENISH_LINE_QTY": '/channel_replenish_line/updateReplenishLineQty',
  "STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_RESERVING": '/channel_replenish/updateToReserving',
  "STOCK_DEMAND_REPLENISHMENT_UPDATE_TO_VERIFY": '/channel_replenish/updateToVerify',
  "STOCK_DEMAND_REPLENISHMENT_GET_STATUS_BY_ID": '/channel_replenish/getStatusCodeById',
  "STOCK_DEMAND_REPLENISHMENT_RESERVE_LIS": '/channel_replenish/reserveToLiS',
  "STOCK_DEMAND_REPLENISHMENT_SUBMIT_TO_LIS": '/channel_replenish/submitToLis',

  "CONFIG_ITEM_TRANSQUOTA_SEARCH": "/config/searchItemTransferQuota",
  "CONFIG_ITEM_TRANSQUOTA_SORTSEARCH": "/config/searchItemTransferQuotaSort",
  "CONFIG_ITEM_TRANSQUOTA_DELETE": "/config/deleteItemTransferQuota",
  "CONFIG_ITEM_TRANSQUOTA_EDIT": "/config/editItemTransferQuota",
  "CONFIG_ITEM_TRANSQUOTA_CREATE": "/config/createItemTransferQuota",

  // "CONFIG_DAY_OF_COVER_SEARCH": "/config_doc/searchDayOfCover",
  // "CONFIG_DAY_OF_COVER_SORTSEARCH": "/config_doc/searchDayOfCoverSort",
  // "CONFIG_DAY_OF_COVER_DELETE": "/config_doc/deleteDayOfCover",
  // "CONFIG_DAY_OF_COVER_EDIT": "/config_doc/editDayOfCover",
  // "CONFIG_DAY_OF_COVER_CREATE": "/config_doc/createDayOfCover",

  "CHANNEL_ITEM_CONFIG_GET_ALL_TYPE": "/channel_item_config/getAllConfigType",
  "CHANNEL_ITEM_CONFIG_SEARCH": "/channel_item_config/search",
  "CHANNEL_ITEM_CONFIG_SEARCH_AVAILABLITY": "/channel_item_config/searchAvailablity",
  "CHANNEL_ITEM_CONFIG_CREATE": "/channel_item_config/create",
  "CHANNEL_ITEM_CONFIG_EDIT": "/channel_item_config/edit",
  "CHANNEL_ITEM_CONFIG_EDIT_AVAILABLITY": "/channel_item_config/editAvailablity",
  "CHANNEL_ITEM_CONFIG_DELETE": "/channel_item_config/delete",
  "CHANNEL_ITEM_CONFIG_LABEL": "/channel_item_config/getAvailabilityLevels",

  // "CONFIG_SEARCH": "/sys_config/search",
  // "CONFIG_SORTSEARCH": "/sys_config/sortSearch",
  // "CONFIG_DELETE": "/sys_config/delete",
  // "CONFIG_EDIT": "/sys_config/edit",
  // "CONFIG_CREATE": "/sys_config/create",

  // "CROSS_SHOP_PICKUP_QUOTA_SEARCH": "/channel_item_control/search",
  // "CROSS_SHOP_PICKUP_QUOTA_CREATE": "/channel_item_control/create",
  // "CROSS_SHOP_PICKUP_QUOTA_EDIT": "/channel_item_control/edit",
  // "CROSS_SHOP_PICKUP_QUOTA_DELETE": "/channel_item_control/delete",

  "UAM_USER_ROLE_SEARCH": "/uam_user_role/search",
  "UAM_USER_ROLE_SORTSEARCH": "/uam_user_role/sortSearch",
  "UAM_USER_ROLE_DELETE": "/uam_user_role/delete",
  "UAM_USER_ROLE_EDIT": "/uam_user_role/edit",
  "UAM_USER_ROLE_CREATE": "/uam_user_role/create",
  "UAM_USER_ROLE_MULTI_CREATE": "/uam_user_role/createMultiMapping",

  "UAM_ROLE_FUNCTION_SEARCH": "/uam_role_function_mapping/search",
  "UAM_ROLE_FUNCTION_SORTSEARCH": "/uam_role_function_mapping/searchBySort",
  "UAM_ROLE_FUNCTION_DELETE": "/uam_role_function_mapping/delete",
  "UAM_ROLE_FUNCTION_EDIT": "/uam_role_function_mapping/edit",
  "UAM_ROLE_FUNCTION_CREATE": "/uam_role_function_mapping/create",

  "UAM_ROLE_SEARCH": "/uam_role/search",
  "UAM_ROLE_SORTSEARCH": "/uam_role/sortSearch",
  "UAM_ROLE_EDIT": "/uam_role/update",
  "UAM_ROLE_DELETE": "/uam_role/delete",
  "UAM_ROLE_CREATE": "/uam_role/create",

  "UAM_FUNCTION_SEARCH": "/uam_function/search",
  "UAM_FUNCTION_SORTSEARCH": "/uam_function/sortSearch",
  "UAM_FUNCTION_EDIT": "/uam_function/update",
  "UAM_FUNCTION_DELETE": "/uam_function/delete",
  "UAM_FUNCTION_CREATE": "/uam_function/create",

  "NOTICE_SEARCH": "/notice/search",
  "NOTICE_SORTSEARCH": "/notice/sortSearch",
  "NOTICE_EVENTTYPE": "/notice/getAllTypes",

  // config table module
  "SYSTEM_MASTER_SEARCH": "/systemMaster/search",
  "SYSTEM_MASTER_EDIT": "/systemMaster/edit",
  "SYSTEM_MASTER_EDIT_ALERT_NOTICE": "/systemMaster/editAlertNotice",
  "SYSTEM_MASTER_EDIT_ORDER_TYPE": "/systemMaster//editOrderType",
  "SYSTEM_MASTER_EDIT_STOCK_RESERVATION_TYPE": "/systemMaster//editStockReservationType",
  "SYSTEM_MASTER_EDIT_SYS_DEF_LOOKUP": "/systemMaster//editSysDefLookup",
  //sys_config
  // "SYS_CONFIG_SEARCH": "/sys_config/sortSearchView",
  // "SYS_CONFIG_SORTSEARCH": "/sys_config/sortSearchView",
  // "SYS_CONFIG_CREATE": "/sys_config/create",
  // "SYS_CONFIG_EDIT": "/sys_config/edit",
  // "SYS_CONFIG_DELETE": "/sys_config/delete",
  //stock_take_label
  // "STOCK_TAKE_LABEL_SEARCH": "/stock_take_label/search",
  // "STOCK_TAKE_LABEL_SORTSEARCH": "/stock_take_label/sortSearch",
  // "STOCK_TAKE_LABEL_CREATE": "/stock_take_label/create",
  // "STOCK_TAKE_LABEL_EDIT": "/stock_take_label/edit",
  // "STOCK_TAKE_LABEL_DELETE": "/stock_take_label/delete",
  //trx_request_order_type
  // "TRX_REQUEST_ORDER_TYPE_SEARCH": "/stockTransferOrderType/orderTypeSearch",
  // "TRX_REQUEST_ORDER_TYPE_SORTSEARCH": "/stockTransferOrderType/orderTypeSearch",
  // "TRX_REQUEST_ORDER_TYPE_CREATE": "/stockTransferOrderType/create",
  // "TRX_REQUEST_ORDER_TYPE_EDIT": "/stockTransferOrderType/update",
  // "TRX_REQUEST_ORDER_TYPE_DELETE": "/stockTransferOrderType/delete",
  //stock_adjustment_reason
  // "STOCK_ADJUSTMENT_REASON_SEARCH": "/stock_adjustment_reason/search",
  // "STOCK_ADJUSTMENT_REASON_SORTSEARCH": "/stock_adjustment_reason/sortSearch",
  // "STOCK_ADJUSTMENT_REASON_CREATE": "/stock_adjustment_reason/create",
  // "STOCK_ADJUSTMENT_REASON_EDIT": "/stock_adjustment_reason/edit",
  // "STOCK_ADJUSTMENT_REASON_DELETE": "/stock_adjustment_reason/delete",
  //RequestStatus
  // "REQUEST_STATUS_SEARCH": "/requestStatus/search",
  // "REQUEST_STATUS_SORTSEARCH": "/requestStatus/sortSearch",
  // "REQUEST_STATUS_CREATE": "/requestStatus/create",
  // "REQUEST_STATUS_EDIT": "/requestStatus/edit",
  // "REQUEST_STATUS_DELETE": "/requestStatus/delete",
  //assignmentLogic/search
  // "ASSIGNMENT_LOGIC_SEARCH": "/assignmentLogic/search",
  // "ASSIGNMENT_LOGIC_SORTSEARCH": "/assignmentLogic/sortSearch",
  // "ASSIGNMENT_LOGIC_CREATE": "/assignmentLogic/create",
  // "ASSIGNMENT_LOGIC_EDIT": "/assignmentLogic/update",
  // "ASSIGNMENT_LOGIC_DELETE": "/assignmentLogic/delete",

  // cas
  "CAS_GET_APPOINTMENT_QUOTA": "/cas_appointment/getAppointmentQuota",
  "CAS_CREATE_APPOINTMENT": "/cas_appointment/createCASAppointment",
  "TASK_RUN": "/kafka_push/push",

  // DM
  "DM_GETDELIVERYDOCUMENT": "/dm/getDeliveryDocument",
  "DM_CREATEDELIVERYDOC": "/dm/createDeliveryDocument", // Create Home Devliery Request

  // edms
  "EDMS_DOWNLOAD": "/data_migration/getEdmsFile",
  "EDMS_UPLOAD": "/data_migration/uploadEdmsFile",
  "EDMS_GET_IDS": "/data_migration/getEdmsFileIds",
  "EDMS_DELETE": "/data_migration/deleteEdmsFile",

  // Kafka Search
  "KAFKA_UPLOADJSONFILEFROMPPOS": "/kafka_test/staffInfoFromKafka",

  // data migration
  "DATA_MIGRATION_UPLOADSTOCKBALANCEFILEFROMPPOS": "/data_migration/uploadStockBalanceFileFromPpos",
  "DATA_MIGRATION_STOCKBALANCEFROMPPOS": "/data_migration/stockBalanceFromPpos",
  "DATA_MIGRATION_EXPORTCOMPAREQTYFILE": "/data_migration/exportCompareQtyFile",
  "DATA_MIGRATION_EXPORTEXCEPTIONFILE": "/data_migration/exportExceptionFile",
  "DATA_MIGRATION_UPLOADSTOCKSERIALNUMBERFILEFROMPPOS": "/data_migration/uploadStockSerialNumberFileFromPpos",
  "DATA_MIGRATION_STOCKSERIALNUMBERFROMPPOS": "/data_migration/stockSerialNumberFromPpos",
  "DATA_MIGRATION_EXPORTSERIALNUMBERLIST": "/data_migration/exportSerialNumberList",
  "DATA_MIGRATION_EXPORTSERIALITEMSUMMARY": "/data_migration/exportSerialItemSummary",
  "DATA_MIGRATION_EXPORTSERIALRECONCILIATIONREPORT": "/data_migration/exportSerialReconciliationReport",
  "DATA_MIGRATION_EXPORTSERIALEXCEPTIONREPORT": "/data_migration/exportSerialExceptionReport",

  //stock transaction lis interface
  "LIS_STOCK_INTERFACE_SEARCH": "/lis_stock_interface/search",
  "LIS_STOCK_INTERFACE_GET_STATUS": "/lis_stock_interface/getStatus",
  "LIS_STOCK_INTERFACE_CALL10U": "/lis_stock_interface/call10u",
  "LIS_STOCK_INTERFACE_CALL10E": "/lis_stock_interface/call10e",

  //reservation interface
  "RESERVATION_INTERFACE_SEARCH":"/reservationInterface/search",
  "RESERVATION_INTERFACE_RETRY": "/reservationInterface/retry",

  //ext_api_request_log
  "EXT_API_REQUEST_LOG_SEARCH": "/ext_api_request_log/search",


  //api_call_log
  "API_CALL_LOG_SEARCH": "/api_call_log/search",

  // item_master
  "ITEM_MASTER_GET_COLUMN": "/item_master/getItemMasterColumn",

  // reason_logic
  "REASONTYPE_GETALL": "/reason/getAllReason",
  "REASONTYPE_GET_ADJUSTMENT_REASONS": "/stockAdjustment/getColorReasons",

  // kafka version
  "KAFKA_VERSION_GETALL": "/kafka_object_version/getAll",

  // lis onhand 1
  "LIS_ONHAND_UPDATE_ONHAND_QTY": "/lis_onhand1/updateOnhandQty",
};

const MENU = {
  STOCK: 'Stock',
  STOCK_BALANCE: 'Stock Balance',
  STOCK_TRANSACTION_HISTORY: 'Transaction History',
  STOCK_MOVEMENT_HISTORY: 'PPOS Daily Stock Movement History',
  DEMAND_CYCLE: 'Demand Cycle',
  // STOCK_REQUEST: 'Stock Request',
  STOCK_ALLOCATION: 'Allocation',
  // STOCK_ADJUSTMENT: 'Stock Adjustment',
  STOCK_TAKE: 'Stock Take',
  STOCK_RESERVATION: 'Reservation',
  STOCK_TRANSACTION: 'Stock Order',
  STOCK_DAILY_TRANSACTION_HISTORY_SUMMARY_REPORT: 'Daily Transaction History Summary Report',
  STOCK_TRANSACTION_LIS_INTERFACE: 'Stock Transaction LIS interface',
  RESERVATION_INTERFACE: 'Reservation Interface',
  STOCK_ITEM_MASTER: 'Item Master',
  STOCK_BACKEND_TRANSACTION: 'Stock Backend Transaction',
  CHANNEL_ITEM_CONTROL: 'Channel Item Control',
  CIC_DOC_CALCULATION_FACTOR: 'DOC Calculation Factor',
  CIC_CROSS_SHOP_PICKUP_QUOTA: 'Cross Shop Pickup Quota',
  CIC_ITEM_TRANSFER_QUOTA: 'Item Transfer Quota',
  CIC_STOCK_AVAILABILITY_LEVEL: 'Stock Availability Level',
  CIC_CONFIGURATION_TABLES_MAINTENANCE: 'Configuration Tables Maintenance',
  QTY_ONHAND_SERIAL: 'Qty Onhand Serial',
  // CIC_CONFIG: 'Config',
  SECURITY: 'Security',
  SECURITY_ROLE_FUNCTION_MAPPING: 'Role Function Mapping',
  SECURITY_USER_ROLE_MAPPING: 'User Role Mapping',
  SECURITY_FUNCTION: 'Access Function',
  SECURITY_ROLE: 'Role',
  NOTICE: 'Notice',
  TASK: 'Task',
  EXT_API_REQUEST_LOG:'External Api Request Log',
  API_CALL_LOG:'Api Call Log',
  PPOS_DATA_MIGRATION: 'PPOS Data Migration',
  KAFKA_TEST:'Kafka Test',
  CHANGE_PASSWORD:'Change Password',
  CATEGORY: {
    ENQUIRIES: 'Enquiries',
    CHANNEL_OPERATIONS: 'Channel Operations',
    CHANNEL_REPLENISHMENT: 'Channel Replenishment',
    STOCK_ASSORTMENT: 'Stock Assortment',
    REPORTS: 'Reports',
    SYSTEM: 'System',
  }
}

const NOTI_TITLE = 'System';

const NOTI_MSG = {
  NETWORK_ERROR: 'Please Check Your Network!',
  MORE_SELECTED_NEED: 'Please Select Some Thing First!',
  ONE_SELECTED_ONLY: 'Only Can Select One Record! ',
  ERROR_ACCOUNTORPASSWORD: 'Invalid Account or Password!',
  NOLOGIN: 'Please Login First!'
};

const CONFIG = {
  // RESERVATION_COM_URL: 'https://here2serve--sita.lightning.force.com/lightning/r/Order/${1}/view', // use for reservation hyperlink open COM new window/tab
  RESERVATION_COM_URL: 'https://here2serve--dev1acom22.lightning.force.com/lightning/r/Order/${1}/view', // use for reservation hyperlink open COM new window/tab
  // RESERVATION_COM_URL: 'https://here2serve--sita.sandbox.lightning.force.com/lightning/r/Order/${1}/view', // use for reservation hyperlink open COM new window/tab
  // CONDITION: {
  //   '1001': 'FG',
  //   '1002': 'FAULTY',
  //   '1003': 'INTRA'
  // },
  CONDITION_CODE: {
    'FG': '1001',
    'FAULTY': '1002',
    'INTRA': '1003'
  },
  SCANNERFORMAT: ['CODE_39', 'CODE_93', 'CODE_128', 'EAN_13', 'EAN_8', 'CODABAR', 'ITF', 'UPC_A', 'UPC_E', 'UPC_EAN_EXTENSION'],
  SCANNERTRYHARDER: false,
  SEARCHPARAMSKEY: {
    STOCKORDER: 'STOCKORDER',
    STOCKTAKE: 'STOCKTAKE',
    STOCKRESERVATION: 'STOCKRESERVATION',
    STOCKALLOCATION: 'STOCKALLOCATION',
    DEMANDCYCLE: 'DEMANDCYCLE'
  },
  LOGINWITHKEYCLOAKDESC: "Login with HKT Login (Non-Prod)",
  RESERVATION_DISALLOW_EDIT_SOURCESYSTEM_CODE: ['COM'],
  TXNHISTORY_SOURCEREFNO_HYPERLINK_FILTER_TXN_TYPE_WHITELIST: ['CHANNEL-TRANSFER','CHANNEL-TRANSFER-COURIER','REPLENISHMENT','RETURN','WAREHOUSE-HOMED','CHANNEL-HOMED','CHANNEL-HOMED-RETURN','WAREHOUSE-HOMED-RETURN'],
  TXNHISTORY_SOURCEREFNO_HYPERLINK_FILTER_SOURCE_SYSTEM_WHITELIST: ['DM2.0'],
  // CANBEREUSELOCALSTOREKEYS: [],
  XLSX_ROW_LIMIT: 1000000, // 1048576
  REPLENISH_ADDITEMBYBU_CHANNEL_BLACKLIST: ['CUR'],
  REPLENISH_REFNO_MAX_LENGTH: 17,
  REPLENISH_REMARK_MAX_LENGTH: 0,
  CHANNEL_WAREHOUSE_TYPE_CODE: 'Warehouse',
  ALLOCATION_EDIT_BUTTON_CHECK_BLACKLIST: ['Channel'],
  REPLENISH_BYOD_CHANNEL_LIST: ['ELX', 'ELG'],
  ORDER_CONDITION_BLACKLIST: ['intra'],
  CAS_APPOINTMENT_QUOTA_PRODTYPE_NOP_WAREHOUSE: ['W005'],
  PRINT_MARGIN_NARROW: {
    left: 0.64/2.54, right: 0.64/2.54,
    top: 1.91/2.54, bottom: 1.91/2.54,
    header: 0.76/2.54, footer: 0.76/2.54
  },
  PRINT_CONFIG_FIT_COLUMN_LANDSCAPE: {
    orientation: 'landscape',
    paperSize: 9,
    margins: {
      left: 0.64/2.54, right: 0.64/2.54,
      top: 1.91/2.54, bottom: 1.91/2.54,
      header: 0.76/2.54, footer: 0.76/2.54
    },
    fitToPage: true,
    fitToWidth: 1,
    fitToHeight: 0,
  }
};

for (const key in URLDICT) {
  if (URLDICT.hasOwnProperty(key)) {
    URLDICT[key] = HOST + URLDICT[key]
  }
}

export {
  HOST,
  MENU,
  URLDICT,
  NOTI_MSG,
  NOTI_TITLE,
  CONFIG,
}
